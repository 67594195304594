import { all, fork } from "redux-saga/effects";

//*Authentication
import RegisterSaga from "./auth/register/saga";
import LoginSaga from "./auth/login/saga";
import ForgotPasswordSaga from "./auth/forgotPassword/saga";
import ResetPasswordSaga from "./auth/resetPassword/saga";
import VerifyOTPSaga from "./auth/verifyOTP/saga";
import ResendOTPSaga from "./auth/resendVerificationOTP/saga";
import LoginWithFacebookSaga from "./auth/loginWithFacebook/saga";

// *common
import ProfileSaga from "./profile/saga";
import HostListProductSaga from "./host_redux/saga";
import ProductDetailSaga from "./product_details/saga";
import PlaceOrderSaga from "./placeOrder/saga";
import FinalPaymentCheckoutSaga from "./finalPaymentCheckout/saga";
import UpdateOrderStatusSaga from "./updateOrderStatus/saga";
import SendMsgSaga from "./chat/send_Message/saga";
import FetchMsgSaga from "./chat/fetch_Message/saga";
import siteSettingSaga from "./siteSettings/saga";
import contactUSSaga from "./contactUs/saga";
import GetCitySaga from "./common/getCities/saga";
import CalculateOrderPaymentSaga from "./calculateOrderPayment/saga"

//*Renter
import RenterViewPaymentSaga from "./renter/viewPayment/saga";
import RenterOrderSaga from "./renter/orderList/saga";
import RenterOrderDetailSaga from "./renter/orderDetails/saga";
import RenterAddReviewSaga from "./renter/addReview/saga";
import RenterUpdateReviewSaga from "./renter/updateReview/saga";
import RenterOrderRenterAgreementSaga from "./renter/updateRenterSignAgreement/saga"

//*Host
import HostViewPaymentSaga from "./host/viewPayment/saga";
import HostOrderSaga from "./host/orderList/saga";
import HostOrderDetailSaga from "./host/orderDetails/saga";
import HostAddReviewSaga from "./host/addReview/saga";
import HostUpdateReviewSaga from "./host/updateReview/saga";
// import HostListProductSaga from "./host/saga";
import HostOrderRenterAgreementSaga from "./host/updateRenterSignAgreement/saga"
import HostProtectionProofSaga from "./host/getProtectionProof/saga";
import HostDeleteProtectionProofSaga from "./host/deleteProtectionProof/saga";

//*Host_product_crud
import HostProductSaga from "./host_product_crud/saga";

//*PromoCode
import PromoCodeSaga from "./promoCode/saga";
import AddPromoCodeSaga from "./addPromoCode/saga";

export default function* rootSaga() {
    yield all([
        //public
        fork(RegisterSaga),
        fork(LoginSaga),
        fork(ProfileSaga),
        fork(HostListProductSaga),
        fork(ForgotPasswordSaga),
        fork(ResetPasswordSaga),
        fork(VerifyOTPSaga),
        fork(ResendOTPSaga),
        fork(LoginWithFacebookSaga),
        fork(ProductDetailSaga),
        fork(PlaceOrderSaga),
        fork(FinalPaymentCheckoutSaga),
        fork(UpdateOrderStatusSaga),
        fork(SendMsgSaga),
        fork(FetchMsgSaga),
        fork(siteSettingSaga),
        fork(contactUSSaga),
        fork(GetCitySaga),
        fork(CalculateOrderPaymentSaga),

        fork(RenterViewPaymentSaga),
        fork(RenterAddReviewSaga),
        fork(RenterUpdateReviewSaga),
        fork(RenterOrderSaga),
        fork(RenterOrderDetailSaga),
        fork(RenterOrderRenterAgreementSaga),

        fork(HostViewPaymentSaga),
        fork(HostOrderSaga),
        fork(HostOrderDetailSaga),
        fork(HostAddReviewSaga),
        fork(HostUpdateReviewSaga),
        fork(HostProductSaga),
        fork(HostOrderRenterAgreementSaga),
        fork(HostProtectionProofSaga),
        fork(HostDeleteProtectionProofSaga),

        fork(PromoCodeSaga),
        fork(AddPromoCodeSaga),
    ]);
}