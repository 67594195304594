import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRenterOrderDetails } from "../../../../Redux/renter/orderDetails/action"
import { finalPaymentCheckout } from "../../../../Redux/finalPaymentCheckout/action"
import { updateOrderStatus } from "../../../../Redux/updateOrderStatus/action";
import ProfileCard from '../../../Pages/Renter/ProfileCard';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import moment from "moment";
import { sendMessageDetails, sendMessageDetailsReset } from '../../../../Redux/chat/send_Message/action'
import { fetchMessageDetails } from '../../../../Redux/chat/fetch_Message/action'
import { signRenterAgreementRenter } from '../../../../Redux/renter/updateRenterSignAgreement/action'
import { clearLocalStorage } from '../../../../helpers/commonfunction'
import { toast } from 'react-toastify';
import { api } from '../../../../config'
import * as url from "../../../../helpers/url_helper";
import { API_CODE, ORDER_STATUS, RENTER_MESSAGE } from '../../../../helpers/enum';
import Parser from 'html-react-parser';
import Modal from 'react-modal';
import OrderProgressSteps from "../../orderProgressSteps";
import { getPromoCodeDetails } from "../../../../Redux/actions";


const RenterOrderDetails = () => {

  const ref = useRef()
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const chatMessagesRef = useRef(null);

  //*state
  const [insuranceTotal, setInsuranceTotal] = useState(0);
  const [securityDepositTotal, setSecurityDepositTotal] = useState(0);
  const [productName, setProductName] = useState("");
  const [productLocation, setProductLocation] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productImage, setProductImage] = useState("");
  const [isAddValue, setIsAddValue] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [productId, setProductId] = useState("");
  const [orderStatus, setOrderStatus] = useState("pending");
  const [loggedUserId, setLoggedUserId] = useState("");
  const [comment, setComment] = useState('');
  const [isImagUpload, setIsImagUpload] = useState(false);
  const [pickUpDate, setPickUpDate] = useState("");
  const [pickupTime, setPickupTime] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [returnTime, setReturnTime] = useState("");
  const [approximateAmount, setApproximateAmount] = useState(0);
  const [promoCodeDiscount, setPromoCodeDiscount] = useState(0);
  const [approximateFinalAmount, setApproximateFinalAmount] = useState(0);
  const [perDays, setPerDays] = useState("0");
  const [finalAmount, setFinalAmount] = useState(0);
  const [todaysAmount, setTodaysAmount] = useState(0);
  const [finalData, setFinalData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [uploadFileName, setUploadFileName] = useState(false);
  const [uploadFileNames, setUploadFileNames] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [docSelectError, setDocSelectError] = useState(false);
  const [isShowLoader, setIsShowLoader] = useState(false);


  //*List
  const [reviewList, setReviewList] = useState([]);
  const [commentsArray, setCommentsArray] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [ImageData, setImageData] = useState([]);

  const success = useSelector(state => state?.RenterOrderDetailReducer?.success);
  const orderDetailLoading = useSelector(state => state?.RenterOrderDetailReducer?.orderDetailLoading);
  const orderDetailData = useSelector(state => state?.RenterOrderDetailReducer?.orderDetailData);
  const error = useSelector(state => state?.RenterOrderDetailReducer?.error);
  const errorMessage = useSelector(state => state?.RenterOrderDetailReducer?.errorMessage);

  const errorMessageFinalPayment = useSelector(state => state?.FinalPaymentCheckoutReducer?.errorMessageFinalPayment);
  const loadingFinalPayment = useSelector(state => state?.FinalPaymentCheckoutReducer?.loadingFinalPayment);
  const successFinalPayment = useSelector(state => state?.FinalPaymentCheckoutReducer?.successFinalPayment);
  const errorFinalPayment = useSelector(state => state?.FinalPaymentCheckoutReducer?.errorFinalPayment);
  const finalPaymentCheckoutData = useSelector(state => state?.FinalPaymentCheckoutReducer?.finalPaymentCheckoutData);

  const sendMsgErrorMessage = useSelector(state => state?.SendMessageReducer?.sendMsgErrorMessage);
  const sendMsgLoading = useSelector(state => state?.SendMessageReducer?.sendMsgLoading);
  const sendMsgData = useSelector(state => state?.SendMessageReducer?.sendMsgData);
  const sendMsgSuccess = useSelector(state => state?.SendMessageReducer?.sendMsgSuccess);
  const sendMsgError = useSelector(state => state?.SendMessageReducer?.sendMsgError);

  const FetchMsgData = useSelector(state => state?.FetchMessageReducer?.fetchMsgData);

  const promoCodeDetailsErrorMessage = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsErrorMessage);
  const promoCodeDetailsLoading = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsLoading);
  const promoCodeDetailsSuccess = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsSuccess);
  const promoCodeDetailsError = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsError);
  const promoCodeDetailsOrderData = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsOrderData);

  const renterRenterAgreementErrorMessage = useSelector(state => state?.RenterRenterAgreementReducer?.renterRenterAgreementErrorMessage);
  const renterRenterAgreementLoading = useSelector(state => state?.RenterRenterAgreementReducer?.renterRenterAgreementLoading);
  const renterRenterAgreementSuccess = useSelector(state => state?.RenterRenterAgreementReducer?.renterRenterAgreementSuccess);
  const renterRenterAgreementError = useSelector(state => state?.RenterRenterAgreementReducer?.renterRenterAgreementError);
  const renterRenterAgreementData = useSelector(state => state?.RenterRenterAgreementReducer?.renterRenterAgreementData);

  const userId = orderDetailData?.order?.user_id;

  useEffect(() => {
    if (!loadingFinalPayment && finalPaymentCheckoutData) {
      let stripeCheckoutURL = finalPaymentCheckoutData.url
      // Open the Stripe checkout URL in a new tab
      window.open(stripeCheckoutURL, "_self");
    }
  }, [successFinalPayment, finalPaymentCheckoutData]);

  useEffect(() => {
    if (!loadingFinalPayment && errorFinalPayment) {
      toast.error(errorMessageFinalPayment);
    }
  }, [errorFinalPayment]);

  useEffect(() => {
    if (!sendMsgLoading && sendMsgData) {
      toast.success("Message sent successfully.");
      onClickClearStates();
    }
  }, [sendMsgSuccess]);

  useEffect(() => {
    if (!sendMsgLoading && sendMsgError) {
      toast.error(sendMsgErrorMessage);
      onClickClearStates();
    }
  }, [sendMsgError]);

  //*expire token
  useEffect(() => {
    if ((error && !orderDetailLoading) || (errorFinalPayment && !loadingFinalPayment) || (sendMsgError && !sendMsgLoading)) {
      if (errorMessage === "Request failed with status code 401" || errorMessageFinalPayment === "Request failed with status code 401" || sendMsgErrorMessage === "Request failed with status code 401") {
        navigation("/login");
        clearLocalStorage();
        toast.error('JWT Expire.');
      }
    }
  }, [error, errorMessage, errorFinalPayment, errorMessageFinalPayment, sendMsgError, sendMsgErrorMessage]);


  useEffect(() => {
    let orderId = localStorage.getItem("orderIdForRenter"); setOrderId(orderId);
    let productId = localStorage.getItem("productIdForRenter"); setProductId(productId);
    if (orderId) {
      let dataObj = {
        "id": orderId,
        "pagination": 0,
      }
      dispatch(getRenterOrderDetails(dataObj));
    }
  }, []);

  useEffect(() => {
    if (renterRenterAgreementSuccess && renterRenterAgreementData) {
      navigation("/renter/order");
      setIsShowLoader(false);
    }
  }, [renterRenterAgreementSuccess, renterRenterAgreementData]);

  useEffect(() => {
    if (renterRenterAgreementError && renterRenterAgreementErrorMessage) {
      toast.error(renterRenterAgreementErrorMessage);
      setIsShowLoader(false);
    }
  }, [renterRenterAgreementError]);

  useEffect(() => {
    let loggedUserId = localStorage.getItem("loggedUserId"); setLoggedUserId(loggedUserId);
    let productId = localStorage.getItem("productIdForRenter");
    if (!orderDetailLoading && orderDetailData) {
      let orderDetailResponse = orderDetailData?.order;
      if (orderDetailResponse?.promo_code) {
        dispatch(getPromoCodeDetails({ promo_code: orderDetailResponse?.promo_code, type: "product" }));
      }
      setOrderData(orderDetailResponse);
      let orderStatus = orderDetailResponse?.order_status; setOrderStatus(orderStatus);
      let productArray = orderDetailResponse?.product_item
      let reviewsData = orderDetailResponse?.reviews_data;
      var reviewsDataResponse = reviewsData.filter((item) => item?.product_id === productId && item?.order_id === orderId); setReviewList(reviewsDataResponse);
      var checkHostReviewResponse = reviewsData.filter((item) => item?.renter_id === loggedUserId);
      if (checkHostReviewResponse.length > 0) { setIsAddValue(false); } else { setIsAddValue(true); }
      var currentProductResponse = productArray.filter((item) => item?.product_id === productId)
      setPickUpDate(moment(orderDetailResponse?.product_item[0]?.pickup_date).format("MM/DD/YYYY"));
      setPickupTime(orderDetailResponse?.product_item[0]?.pickup_time);
      setReturnDate(moment(orderDetailResponse?.product_item[0]?.return_date).format("MM/DD/YYYY"))
      setReturnTime(orderDetailResponse?.product_item[0]?.return_time);
      const pickupDate = new Date(orderDetailResponse?.product_item[0]?.pickup_date);
      const returnDate = new Date(orderDetailResponse?.product_item[0]?.return_date);
      pickupDate.setHours(0, 0, 0, 0);
      returnDate.setHours(0, 0, 0, 0);
      const timeDifference = returnDate.getTime() - pickupDate.getTime() + (24 * 60 * 60 * 1000);
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      const approximateFinalAmount = daysDifference * orderDetailResponse.product_item[0]?.product_data.price_per_day;
      setApproximateAmount(approximateFinalAmount === 0 ? orderDetailResponse.product_item[0]?.product_data.price_per_day : approximateFinalAmount)
      setPromoCodeDiscount(orderDetailResponse?.discount_amount);
      // let calculateFinalAmount = (approximateFinalAmount - orderDetailResponse.discount_amount); 
      let totalAmount = orderDetailResponse?.order_total_amount
      setApproximateFinalAmount(totalAmount);
      setPerDays(daysDifference === 0 ? "1" : daysDifference)

      if (currentProductResponse.length > 0) {
        let location = currentProductResponse[0]?.product_data?.product_location[0]
        setProductName(currentProductResponse[0]?.product_data?.product_name); setProductLocation(location);
        setProductPrice(currentProductResponse[0]?.product_data?.price_per_day); setProductImage(currentProductResponse[0]?.product_data.images_detail[0]?.path);
        setInsuranceTotal(daysDifference * currentProductResponse[0]?.product_data.insurance_amount);
        setSecurityDepositTotal(currentProductResponse[0]?.product_data?.security_deposit)
        let finalAmount = orderDetailResponse?.final_order_data?.final_total_amount;
        setFinalAmount(finalAmount);
        setFinalData(orderDetailResponse?.final_order_data);

        const pickupDateForToday = new Date(orderDetailResponse?.product_item[0]?.pickup_date);
        const currentDate = new Date();
        const timeDifferenceForToday = currentDate - pickupDateForToday;
        const daysDifferenceForToday = Math.floor(timeDifferenceForToday / (1000 * 60 * 60 * 24));
        var calculateTodaysDate = daysDifferenceForToday === 0 ? 1 * currentProductResponse[0]?.product_data?.price_per_day : daysDifferenceForToday * currentProductResponse[0]?.product_data?.price_per_day;
        setTodaysAmount(calculateTodaysDate);

      }
    }
  }, [success])

  useEffect(() => {
    dispatch(fetchMessageDetails(orderId));
  }, [orderId])

  useEffect(() => {
    if (FetchMsgData) {
      setCommentsArray(FetchMsgData.toReversed());
    }
  }, [FetchMsgData]);

  //* Add Review
  const onClickAddReview = (orderId, productId) => {
    localStorage.setItem("isAddRenter", isAddValue);
    navigation("/renter/review");
  };

  //*Update Review
  const onClickUpdateReview = (item) => {
    localStorage.setItem("isAddRenter", isAddValue);
    localStorage.setItem('renterReviewUpdateData', JSON.stringify(item));
    navigation("/renter/review");
  };

  //*Final Payment
  const onClickFinalPayment = () => {
    let dataObj = { "id": orderId }
    dispatch(finalPaymentCheckout(dataObj));
  };

  //* Add Comments
  const handleCaptureComments = () => {
    if (comment === '') { toast.error("Please enter the comment"); return false; }
    if (isImagUpload === true) {
      handleImageChange(fileList);
      sendMsgWithDoc();
      onClickClearStates();
    } else {
      let data = { message: comment, user_id: userId };
      let previousCommentData = [...commentsArray, data];
      sendMessage();
      setCommentsArray(previousCommentData);
    }
  };

  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [commentsArray]);

  //*Send Message
  const sendMessage = () => {
    let orderId = localStorage.getItem("orderIdForRenter");
    // let userId = localStorage.getItem("userIdRenter");
    let getLoggedUserIdRole = localStorage.getItem("role");
    let userId;
    if (getLoggedUserIdRole === "host") {
      let orderDetailResponse = orderDetailData?.order;
      userId = orderDetailResponse?.user_id
    } else {
      let orderDetailResponse = orderDetailData?.order;
      userId = orderDetailResponse?.product_item[0]?.product_data?.host_detail?._id
    }

    let dataObj = {
      // "user_id": userId,
      orderId: orderId,
      message: comment,
      // "proof_docs": "",
    }
    dispatch(sendMessageDetails(dataObj));
    scrollToBottom();
  };

  //*clear States
  const onClickClearStates = () => {
    dispatch(sendMessageDetailsReset());
    setComment(""); setIsImagUpload(false);
  };

  //*Handle Image
  const handleImageChange = (file) => {
    if (file && file.length) {
      //append images
      for (let [key, itm] of Object.entries(file)) {
        const reader = new FileReader();
        reader.onload = () => {
          let data = { "item": reader.result, "type": "image" };
          setImageData(ImageData.push(data));
          setCommentsArray(ImageData);
        };
        reader.readAsDataURL(itm);
      }
    }
  };

  const sendMsgWithDoc = () => {

    let dataAppend = new FormData();
    let orderId = localStorage.getItem("orderIdForRenter");
    // let userId = localStorage.getItem("userIdRenter");
    let token = localStorage.getItem("accessToken");
    let headers = {};

    let getLoggedUserIdRole = localStorage.getItem("role");
    let userId;
    if (getLoggedUserIdRole === "host") {
      let orderDetailResponse = orderDetailData?.order;
      userId = orderDetailResponse?.user_id
    } else {
      let orderDetailResponse = orderDetailData?.order;
      userId = orderDetailResponse?.product_item[0]?.product_data?.host_detail?._id
    }

    let dataObj = {
      "user_id": userId,
      "order_id": orderId,
      "message": comment,
    }

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    //append images
    for (let [key, itm] of Object.entries(fileList)) {
      dataAppend.append('proof_docs', itm, itm?.name);
    }

    //append remaining data
    for (let [key, itm] of Object.entries(dataObj)) {
      if (key !== 'proof_docs') {
        dataAppend.append(key, itm);
      }
    }

    var requestOptions = {
      method: 'POST',
      headers,
      body: dataAppend,
      redirect: 'follow'
    };

    fetch(`${api.API_URL}${url.POST_SEND_MESSAGE}`, requestOptions)
      .then(response => response.json())
      .then((result) => {
        //need to redirect to login page
        if (result.statusCode === API_CODE.SUCCESS) {
          toast.success("Message sent successfully.");
          onClickClearStates();
        }
      })
      .catch(error => console.log('error', error));
  }

  function uploadPluginNew(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapterNew(loader);
    };
  }

  const allFiles = [];
  function uploadAdapterNew(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            setIsImagUpload(true)
            allFiles.push(file);
            setFileList(allFiles);
            resolve(true);
          });
        });
      }
    };
  }

  function extractTextFromHTML(htmlString) {
    // Create a temporary div element
    const tempDiv = document.createElement('div');
    // Set the innerHTML of the div to the HTML string
    tempDiv.innerHTML = htmlString;
    // Get the text content of the div
    const textContent = tempDiv.textContent;
    return textContent;
  }

  //* Update Status
  const onClickUpdateStatus = (status) => {

    let orderId = localStorage.getItem("orderIdForRenter");
    let dataAppend = new FormData();
    for (let [key, itm] of Object.entries(uploadFileNames)) {
      dataAppend.append('agreement_doc', itm, itm?.name);
    }
    dataAppend.append('order_status', status);

    let token = localStorage.getItem("accessToken");
    let headers = {};
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    var requestOptions = {
      method: 'POST',
      headers,
      body: dataAppend,
      redirect: 'follow'
    };
    fetch(`${api.API_URL}${url.POST_RENTER_UPDATE_STATUS}${orderId}`, requestOptions)
      .then(response => response.text())
      .then((result) => {
        var getRenterStatusData = JSON.parse(result)
        if (getRenterStatusData?.statusCode === API_CODE.SUCCESS) {
          setShowModal(false);
          if (orderStatus === ORDER_STATUS.FINAL_AMOUNT_AUTO_DEDUCT) { toast.success(RENTER_MESSAGE.CHECKIN); }
          navigation("/renter/order");
          setIsShowLoader(false);
        }
      })
      .catch(error => console.log('error', error));
  };

  //* Update Renter Agreement
  const onClickUpdateRenterAgreement = () => {
    toast.success(RENTER_MESSAGE.AGREEMENT_SIGN);
    let orderId = localStorage.getItem("orderIdForRenter");
    let dataObj = {
      "order_id": orderId,
      "is_Sign_Rental_Agreement_From_Renter": true,
    };
    dispatch(signRenterAgreementRenter(dataObj));
  };


  const onClickRemoveDocuments = (index) => {
    let data = [...uploadFileNames];
    data.splice(index, 1)
    setUploadFileNames(data)
  }

  const handleClick = (e) => {
    ref.current.click()
  }
  //*Select Doc
  async function selectDocuments(e) {
    let file = e.target.files;
    let imageFiles = [];
    if (uploadFileNames.length > 0) {
      for (let i = 0; i < file.length; i++) {
        imageFiles.push(file[i]);
        if (i === file.length - 1) {
          let finalData = [...uploadFileNames, ...imageFiles];
          setUploadFileNames(finalData);
          // setFieldValue("attachment", finalData.length);
        }
      }
    } else {
      for (let i = 0; i < file.length; i++) {
        imageFiles.push(file[i]);
        if (i === file.length - 1) {
          let finalData = [...imageFiles];
          setUploadFileNames(finalData);
          // setFieldValue("attachment", finalData.length);
        }
      }
    }
  }

  return (
    <div>
      <section className="innerBanner-wrapper">
        <div className="innerBanner-img img-obj cover">
          <img src="images/header.png" alt="header_image" />
        </div>
        <div className="container h-100 z1 flex-all">
          <h1>Order Details</h1>
        </div>
      </section>
      {/* Profile */}
      <section className="profile-wrapper py32-wrapper profile-order-btn px-18">
        <div className="container-fluid">
          {isShowLoader === true &&
            <div id="loader" data-wordload="Please Wait"></div>
          }
          <div className="row g-4">
            <ProfileCard />
            <div className="col-md-9 col-sm-8 profile-main">
              <OrderProgressSteps dataFromParent={orderData?.order_progress_step} />
              {
                orderDetailData?.order?.order_status === ORDER_STATUS.PENDING ?
                  <h4 >{RENTER_MESSAGE.ORDER_RENTAL}</h4> :
                  orderDetailData?.order?.order_status === ORDER_STATUS.RENTER_AGREEMENT ?
                    <h4>{RENTER_MESSAGE.CHECKOUT}</h4> :
                    orderDetailData?.order?.order_status === ORDER_STATUS.CHECK_IN ?
                      <h4>{RENTER_MESSAGE.CHECKIN}</h4> :
                      orderDetailData?.order?.order_status === ORDER_STATUS.RETURN_DEPOSIT_ACCEPTED ?
                        <h4 >{RENTER_MESSAGE.ORDER_APPROVE}</h4> :
                        orderDetailData?.order?.order_status === ORDER_STATUS.RETURN_DEPOSIT_REJECTED ?
                          <h4 >{RENTER_MESSAGE.ORDER_REJECT}</h4>
                          : null
              }
              {
                orderStatus === ORDER_STATUS.ACCEPTED ?
                  <div className="row">
                    <div className="col-lg-12 col-sm-12 col-md-12">
                      <div className="mb-2 agrement-btn float-end">
                        <Link onClick={() => {
                          // setShowModal(true);
                          setIsShowLoader(true);
                          onClickUpdateRenterAgreement();
                        }}
                          to="https://docuseal.co/d/sP7juvsvUTNxxL"
                          className="btn btn-green"
                          target="_blank"
                        >
                          <span>{"Sign Rental Agreement"}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  :
                  // orderStatus === ORDER_STATUS.AGREEMENT_SENDED ?
                  //   <div className="row">
                  //     <div className="col-lg-12 col-sm-12 col-md-12">
                  //       <div className="mb-2 agrement-btn float-end">
                  //         <Link
                  //           onClick={() => {
                  //             setShowModal(true);
                  //           }}
                  //           className="btn btn-green"
                  //         >
                  //           <span>{"Send Agreement To Host"}</span>
                  //         </Link>
                  //       </div>
                  //     </div>
                  //   </div> :
                  orderStatus === ORDER_STATUS.RENTER_AGREEMENT ?
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 col-md-12">
                        <div className="mb-2 agrement-btn float-end">
                          <Link
                            onClick={() => {
                              setShowModal(true);
                            }}
                            className="btn btn-green"
                          >
                            <span>{"Check out"}</span>
                          </Link>
                        </div>
                      </div>
                    </div> :
                    orderStatus === ORDER_STATUS.FINAL_AMOUNT_AUTO_DEDUCT ?
                      <div className="row">
                        <div className="col-lg-12 col-sm-12 col-md-12">
                          <div className="mb-2 agrement-btn float-end">
                            <Link
                              onClick={() => {
                                setShowModal(true);
                              }}
                              className="btn btn-green"
                            >
                              <span>{"Check in"}</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                      : null
              }
              <div className="table-responsive mb-5 bg-lightTheme p-3">
                <table className="table-themeBr">
                  <tbody>
                    <tr>
                      <th>Order Id</th>
                      <td># {orderData?._id}</td>
                      <th>Order Reference Id</th>
                      <td># {orderData?.client_reference_id}</td>
                    </tr>
                    <tr>
                      <th>INITIAL TOTAL:</th>
                      {/* <td>{"$ " + orderData?.order_total_amount / 100 + " / $ " + approximateAmount}</td> */}
                      <td>{"$ " + (orderData?.order_total_amount.toFixed(2)) + " / $ " + approximateAmount.toFixed(2)}</td>
                      <th>Payment Status</th>
                      <td>{orderData?.payment_status}</td>
                    </tr>
                    {
                      orderData?.is_delivery && (
                        <>
                          <tr>
                            <th>Delivery Address</th>
                            <td>{orderData?.delivery_address}</td>
                            <th>Delivery Distance</th>
                            <td>{orderData?.delivery_distance}</td>
                          </tr>
                          <tr>
                            <th>Delivery Charge Per Mile</th>
                            <td>$ {orderData?.product_item[0]?.product_data?.delivery_charge.toFixed(2)}</td>
                            <th>{"Total Delivery Amount ( $" + orderData?.product_item[0]?.product_data?.delivery_charge + " * " + orderData?.delivery_distance + ")"}</th>
                            <td>$ {orderData?.delivery_charge.toFixed(2)}</td>
                          </tr>
                        </>
                      )}
                  </tbody>
                </table>
              </div>
              <div className="card ms-xl-1">
                <div className="card-header">
                  <h3>Deposit Order Details</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col cart-main">
                      <div className="table-responsive">
                        <table className="table-themeBr table-cart">
                          <thead>
                            <tr>
                              <th>PRODUCT</th>
                              <th>LOCATION</th>
                              <th>PICK-UP DATE</th>
                              <th>RETURN DATE</th>
                              <th>APPROXIMATE AMOUNT</th>
                              <th>PRICE</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="d-inline-flex align-items-center">
                                  <div className="ratio"><img src={productImage} alt="prdt_img" /></div>
                                  {productName.length > 15 ? (
                                    <td className="product-break-word">{productName}</td>
                                  ) : (
                                    <h5>{productName}</h5>
                                  )}
                                </div>
                              </td>
                              <td>{productLocation}</td>
                              <td>{pickUpDate}</td>
                              <td>{returnDate}</td>
                              <td>${approximateAmount + " for " + perDays + " Days"} </td>
                              <td>${productPrice}<span>/ Day</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div>
                      </div>
                    </div>
                    <div className="col-md-auto">
                      <div className="price-details mt-2">
                        <h5>PRICE DETAILS</h5>
                        <div className="table-responsive mb-3">
                          <table className="table-themeBr">
                            <tbody>

                              <tr>
                                <th>REFUNDABLE DEPOSIT:</th>
                                <td>${securityDepositTotal.toFixed(2)}</td>
                              </tr>
                              <tr>
                                <th>TOTAL:</th>
                                <th>
                                  $ {securityDepositTotal.toFixed(2)}
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card ms-xl-1 mt-4 text-right">
                <div className="card-header">
                  <h3>Final Details</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="table-responsive mb-5 bg-lightTheme p-3">
                      <table className="table-themeBr">
                        <tbody>
                          <tr>
                            <th>Order Id</th>
                            <td># {orderData?._id}</td>
                            <th>Final Order Reference Id</th>
                            <td># {finalData?.client_reference_id}</td>
                          </tr>
                          <tr>
                            <th>Final Total</th>
                            <td>{((finalData?.final_total_amount) ? `$ ${(finalData?.final_total_amount / 100)}` : "N/A")}</td>
                            <th>Final Payment Status</th>
                            <td>{finalData?.payment_status ? finalData?.payment_status : "N/A"}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col cart-main">
                      <div className="table-responsive">
                        <table className="table-themeBr table-cart">
                          <thead>
                            <tr>
                              <th>PRODUCT</th>
                              <th>LOCATION</th>
                              <th>PICK UP DATE</th>
                              <th>RETURN DATE</th>
                              <th>APPROXIMATE AMOUNT</th>
                              <th>PRICE</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="d-inline-flex align-items-center">
                                  <div className="ratio"><img src={productImage} alt="fnl_prdt_img" /></div>
                                  {productName?.length > 15 ? (
                                    <td className="product-break-word">{productName}</td>
                                  ) : (
                                    <td>{productName}</td>
                                  )}
                                </div>
                              </td>
                              <td>{productLocation}</td>
                              <td>{pickUpDate}</td>
                              <td>{returnDate}</td>
                              <td>${approximateAmount + " for " + perDays + " Days"} </td>
                              <td>${productPrice}<span>/ Day</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div>
                      </div>
                    </div>
                    <div className="col-md-auto">
                      <div className="price-details mt-2">
                        <h5>Final Amount Details</h5>
                        <div className="table-responsive mb-3">
                          <table className="table-themeBr">
                            <tbody>
                              {
                                orderData?.is_delivery &&
                                <tr>
                                  <th>
                                    {
                                      `DELIVERY CHARGE PER MILE ( $ ${orderData?.product_item[0]?.product_data?.delivery_charge.toFixed(2)} )`
                                    }
                                  </th>
                                  <td>${orderData?.delivery_charge.toFixed(2)}</td>
                                </tr>
                              }
                              <tr>
                                <th>{"Protection plan : ($" + orderDetailData?.order?.product_item[0]?.product_data.insurance_amount + ") a day" + " * (" + perDays + ")"}</th>
                                <td>${insuranceTotal.toFixed(2)}</td>
                              </tr>
                              <tr>
                                <th>{"Amount per day : ($" + orderDetailData?.order?.product_item[0]?.product_data.price_per_day + ")" + " * (" + perDays + ")"}</th>
                                <td>${(approximateAmount > 0) ? approximateAmount.toFixed(2) : ""}</td>
                              </tr>
                              <tr>
                                {
                                  promoCodeDetailsOrderData?.promocode?.discount ?
                                    <th>Promo Code Discount ( {promoCodeDetailsOrderData?.promocode?.discount} %  ) :</th> :
                                    <th>Promo Code Discount ( {"0"} %  ) :</th>
                                }
                                <td>${(promoCodeDiscount > 0) ? promoCodeDiscount.toFixed(2) : "0"}</td>
                              </tr>
                              <tr>
                                <th>Final Amount:</th>
                                <td>${(approximateFinalAmount > 0) ? approximateFinalAmount.toFixed(2) : ""}</td>
                              </tr>
                              {/* {
                                orderStatus === ORDER_STATUS.ACCEPTED &&
                                <tr>
                                  <th>Today's Amount:</th>
                                  <td>${todaysAmount.toFixed(2)}</td>
                                </tr>
                              } */}
                            </tbody>
                          </table>
                        </div>
                        {finalAmount &&
                          <div className="text-center">
                            <h5>Final Amount Paid</h5>
                            <h4>${(finalAmount) ? (finalAmount / 100).toFixed(2) : ""}</h4>
                          </div>
                        }
                        {/* {
                          orderStatus === ORDER_STATUS.ACCEPTED &&
                          <div className="text-center mt-2">
                            <button onClick={() => {
                              onClickFinalPayment();
                            }} className="btn btn-green"><span>{"Final Payment"}</span></button>
                          </div>
                        } */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      <section className="reviews-wrapper pyCustom-wrapper bg-lightTheme ">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="row">
                <div className="col-md-1">
                  <h3 className="mb-5">Reviews</h3>
                </div>
                <div className="col-md-11">
                  {
                    isAddValue && orderStatus === ORDER_STATUS.RETURN_DEPOSIT_ACCEPTED || orderStatus === ORDER_STATUS.RETURN_DEPOSIT_REJECTED ?
                      <div className="text-center">
                        <button
                          onClick={() => {
                            onClickAddReview();
                          }}
                          className="btn btn-green mt-3"
                        >
                          <span>{"Add Review"}</span>
                        </button>
                      </div>
                      : null}
                </div>
              </div>
              {reviewList.map((item, index) => {
                return (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                    }}
                    key={index}
                  >
                    <div className="reviews-block">
                      <div>
                        <div className="rating">
                          {[1, 2, 3, 4, 5].map((starValue) => (
                            <i
                              key={starValue}
                              className={`fa-solid fa-star ${starValue <= item?.rate ? "selected" : ""
                                }`}
                            ></i>
                          ))}
                        </div>
                        <h6>{item?.text}</h6>
                      </div>
                    </div>
                    {item?.renter_id === loggedUserId && (
                      <div>
                        <button
                          onClick={() => {
                            onClickUpdateReview(item);
                          }}
                          className="svg-order btn-green btn-icon"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 22.061 21.828"
                          >
                            <path
                              d="M17,3a2.828,2.828,0,0,1,4,4L7.5,20.5,2,22l1.5-5.5Z"
                              transform="translate(-1 -1.172)"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="col-md-7">
              {
                <div className="chat-box">
                  <div className="chat-header">
                    <h2>Chat with host</h2>
                  </div>
                  <div className="chat-messages" ref={chatMessagesRef}>
                    {commentsArray?.map((comment, index) => {
                      return (
                        <div
                          key={index}
                          className={`message ${comment.user_id === userId ? "right" : "left"}`}
                        >
                          <div className="message-content">
                            {Parser(comment?.message)}
                          </div>
                          <div className="text-muted small" style={{fontSize: '11px'}}>
                            {moment(comment.created).calendar(null, {
                              sameDay: '[Today,] h:mm A',
                              lastDay: '[Yesterday,] h:mm A',
                              lastWeek: 'ddd, h:mm A',
                              sameElse: function(now) {
                                return Math.abs(moment().diff(comment.created, 'months')) > 6 
                                  ? 'DD MMM YYYY, h:mm A'
                                  : 'ddd, DD MMM, h:mm A';
                              }
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="chat-input row g-2">
                    {/* <textarea
                    style={{ width: "100%" }}
                    rows="3"
                    placeholder="Type your message..."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  /> */}
                    <div className="col-lg-10">
                      {
                        <CKEditor
                          config={{
                            toolbar: [],
                            extraPlugins: [uploadPluginNew],
                          }}
                          editor={ClassicEditor}
                          data={comment}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            // console.log('Editor is ready to use!', editor);
                          }}
                          onChange={(event, editor) => {
                            /* const data = editor.getData();
                        ImageData = [{ "item": data, "type": "text" }];
                        setComment(data); */
                            // console.log({ event, editor, data });
                          }}
                          onBlur={(event, editor) => {
                            const data = editor.getData();
                            const stringWithoutPTags = extractTextFromHTML(data);
                            setImageData([{ item: stringWithoutPTags, type: "text" }]);
                            setComment(stringWithoutPTags);
                            // console.log('Blur.', editor);
                          }}
                          onFocus={(event, editor) => {
                            // console.log('Focus.', editor.data.get());
                          }}
                        />
                      }
                    </div>
                    <div className="col-lg-2 cke-btn">
                      <button
                        // style={{ margin: "2px", width: "10%" }}
                        className="btn-green cke-send-btn"
                        onClick={handleCaptureComments}
                        disabled={sendMsgLoading}
                      >
                        {sendMsgLoading ? "Sending..." : "Send"}
                      </button>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </section>
      <Modal
        isOpen={showModal}
        onRequestClose={() => { setShowModal(false) }}
        style={{
          content: {
            width: "500px", height: uploadFileNames.length > 0 ? "550px" : "auto", top: "50%", left: "50%", transform: "translate(-50%,-50%)",
            display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "top",
          },
        }}
      >
        <h2>Image Upload</h2>
        {
          !uploadFileName &&
          <div>
            <Link onClick={() => {
              handleClick();
            }}
              className={docSelectError ? "btn btn-green mt-2 mb-2 form-control-error" : "btn btn-green mt-2 mb-2"}
            ><span>Browse</span></Link>
            <input ref={ref} type="file"
              // accept=".pdf,.doc,.docx" 
              accept={orderStatus === ORDER_STATUS.AGREEMENT_SENDED ? ".pdf,.doc,.docx" : ".jpg, .jpeg, .png, .webp"}
              onChange={(e) => { selectDocuments(e) }} multiple="multiple" style={{ display: 'none' }} />
            {docSelectError && <p className="error-text">{"Please Select Image."}</p>}
          </div>
        }
        {/* {
          uploadFileNames.length > 0 &&
          uploadFileNames.map((item, index) => {
            return (
              <h5 className="mt-2 mb-2">{item}</h5>
            )
          })
        } */}
        {

          <div className="cartImagesBlock mb-3">
            <div className="row">
              {
                uploadFileNames.length > 0 &&
                uploadFileNames.map((image, index) => (
                  <div className="col-lg-3">
                    <div className="image-scrollbarUpdateProduct checkout-upload-img">
                      <div key={index} className="image-preview">
                        <div className="upload-img no-img cover w-auto">
                          <div className="overflow-hidden">
                            <img
                              src={URL.createObjectURL(image)}
                              alt={`prd_Image ${index}`}
                            />
                          </div>
                          <img src="images/cancelImage.png" alt="header_image" style={{ position: 'absolute', top: -10, right: -10, height: 20, width: 20, borderRadius: '50%' }}
                            onClick={() => {
                              onClickRemoveDocuments(index);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

        }
        <div className="flex">
          <button className='btns btn-lg btn-upload mx-2'
            disabled={!uploadFileNames || uploadFileNames.length <= 0}
            onClick={() => {
              if (uploadFileNames !== null && uploadFileNames !== undefined) {
                setIsShowLoader(true);
                if (orderStatus === ORDER_STATUS.RENTER_AGREEMENT) {
                  onClickUpdateStatus(ORDER_STATUS.CHECK_OUT);
                } else if (orderStatus === ORDER_STATUS.FINAL_AMOUNT_AUTO_DEDUCT) {
                  onClickUpdateStatus(ORDER_STATUS.CHECK_IN);
                }
                setShowModal(false);
                setDocSelectError(false);
                // setIsShowLoader(false);
              } else {
                setDocSelectError(true);
                setIsShowLoader(false);
              }
            }}><span>Send</span></button>
          <button className='btns btn-lg btn-secondary' onClick={() => { setShowModal(false); setUploadFileName(""); setUploadFileNames([]); }}><span>Cancel</span></button>
        </div>
        <hr />
        <div>
          {orderStatus === ORDER_STATUS.RENTER_AGREEMENT ? (
            <p>
              <b>Check out:</b> Upload pictures of the current product condition. This must be pictures taken at the time of Check out (before picking up your product from the host).
            </p>
          ) : (
            <p>
              <b>Check in:</b> Upload pictures of the current product condition. This must be pictures taken at the time of Check in (after returning your product from the host).
            </p>
          )}
        </div>
      </Modal>
    </div >
  );
};
export default RenterOrderDetails;